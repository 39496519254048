import { ClientBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { AuthService } from '../../../auth/auth.service';
import { CLIENT_ROUTES } from '../../route';
import { ClientService } from '../../../api/client/client.service';
import { UserAction } from '../../../model/actions/user.action';
import {
  useAddAction,
  useEditAction,
  useRemoveAction,
  useToggleActiveAction,
} from '../../../model/actions/common.actions';

type AvailableActions = 'add' | 'edit' | 'remove' | 'toggleActive' | 'toggleVerified';

export function useActions(): Record<AvailableActions, UserAction<ClientBrief>> {
  const { t } = useTranslation();
  const user = useMemo(() => AuthService.getUser(), []);
  const canModify = useCallback(
    (entity?: ClientBrief) => entity != undefined && entity.activeVersion && user != null && (user.isAdmin || user.uuid === entity.owner.uuid),
    [user],
  );
  const add = useAddAction<ClientBrief>(CLIENT_ROUTES);
  const edit = useEditAction<ClientBrief>(CLIENT_ROUTES, (entity) => canModify(entity));
  const remove = useRemoveAction<ClientBrief>(ClientService, (entity) => canModify(entity));
  const toggleActive = useToggleActiveAction<ClientBrief>({
    service: ClientService,
    available: (entity) => user?.isAdmin === true && entity?.activeVersion === true,
    localeConfirmNS: 'client',
  });

  return {
    add,
    edit,
    remove,
    toggleActive,
    toggleVerified: {
      key: 'toggleVerified',
      btnText: () => t(`client:button.toggleVerified.false`),
      btnColor: () => 'warning',
      available: (entity) => user?.isAdmin === true && entity?.isVerified === true,
      action: (entity) => ClientService.toggle('isVerified', entity?.uuid ?? '', false),
      confirm: () => ({
        description: 'client:confirm.toggleVerified',
      }),
    }
  };
}
