import { UserAction } from '../../../model/actions/user.action';
import { UserBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { AuthService } from '../../../auth/auth.service';
import {
  useAddAction,
  useEditAction,
  useRemoveAction,
  useToggleActiveAction,
} from '../../../model/actions/common.actions';
import { USER_ROUTES } from '../../route';
import { UserService } from '../../../api/user/user.service';

type AvailableActions = 'add' | 'edit' | 'remove' | 'toggleActive' | 'toggleAdmin' | 'toggleEmail' | 'togglePhone';

export function useActions(): Record<AvailableActions, UserAction<UserBrief>> {
  const { t } = useTranslation();
  const user = useMemo(() => AuthService.getUser(), []);
  const canModify = useCallback(
    (entity?: UserBrief) => entity != undefined &&  !entity.isDeleted && user != null && (user.isAdmin || user.uuid === entity.uuid),
    [user],
  );
  const canToggle = useCallback(
    (entity?: UserBrief) => entity != undefined &&  !entity.isDeleted && user != null && user.isAdmin && !entity?.isAdmin && entity?.uuid !== user?.uuid,
    [user],
  );
  const add = useAddAction<UserBrief>(USER_ROUTES, () => user?.isAdmin == true);
  const edit = useEditAction<UserBrief>(USER_ROUTES, (entity) => canModify(entity) && (!entity?.isAdmin || entity?.uuid === user?.uuid));
  const remove = useRemoveAction<UserBrief>(UserService, (entity) => canToggle(entity));
  const toggleActive = useToggleActiveAction<UserBrief>({
    service: UserService,
    available: (entity) => canModify(entity) && !entity?.isAdmin && entity?.uuid !== user?.uuid,
    localeConfirmNS: 'user',
  });

  return {
    add,
    edit,
    remove,
    toggleActive,
    toggleAdmin: {
      key: 'toggleAdmin',
      btnText: (entity) => t(`user:button.toggleAdmin.${!entity?.isAdmin}`),
      btnColor: (entity) => entity?.isAdmin ? 'warning' : 'success',
      available: (entity) => user?.isSuperAdmin === true && !entity?.isDeleted,
      action: (entity) => UserService.toggle('isAdmin', entity?.uuid ?? '', !entity?.isAdmin),
      confirm: (entity) => ({
        description: `user:confirm.toggleAdmin.${!entity?.isAdmin}`,
      }),
    },
    toggleEmail: {
      key: 'toggleEmail',
      btnText: (entity) => t(`user:button.toggleEmail.${!entity?.emailVerified}`),
      btnColor: (entity) => entity?.emailVerified ? 'warning' : 'success',
      available: (entity) => canToggle(entity),
      action: (entity) => UserService.toggle('emailVerified', entity?.uuid ?? '', !entity?.emailVerified),
      confirm: (entity) => ({
        description: `user:confirm.toggleEmail.${!entity?.emailVerified}`,
      }),
    },
    togglePhone: {
      key: 'togglePhone',
      btnText: (entity) => t(`user:button.togglePhone.${!entity?.phoneVerified}`),
      btnColor: (entity) => entity?.phoneVerified ? 'warning' : 'success',
      available: (entity) => canToggle(entity),
      action: (entity) => UserService.toggle('phoneVerified', entity?.uuid ?? '', !entity?.phoneVerified),
      confirm: (entity) => ({
        description: `user:confirm.togglePhone.${!entity?.phoneVerified}`,
      }),
    },
  }
}