import { ApiError, BaseEntity } from '../../../../model';
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useState } from 'react';
import { EntityQueryKey } from '../../../../query/query.keys';
import { EntityRoutes } from '../../../../model/route/entity.routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useErrorHandler } from '../../../../hooks/UseErrorHandler';
import { useConfirm } from 'material-ui-confirm';
import { Title } from '../../typography';
import { Content } from '../Content';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { resetQueryFn } from '../../../../utils';
import { CrudService } from '../../../../api/crud.service';
import { useSlots } from '../../../../hooks/UseSlots';
import { AuthService } from '../../../../auth/auth.service';
import { UserAction } from '../../../../model/actions/user.action';
import { useSnackbar } from 'notistack';

interface IProps<TEntity extends BaseEntity> extends PropsWithChildren {
  titleKey?: string;
  titleParams?: (entity?: TEntity) => any[];
  queryKey: EntityQueryKey;
  entitySetter: Dispatch<SetStateAction<TEntity | undefined>>;
  routes: EntityRoutes;
  service: CrudService;
  apiErrors?: (errors: Record<string, string>) => void;
  loading?: boolean;
  actions?: UserAction<TEntity>[];
}

function Buttons({ children }: PropsWithChildren) {
  return <>{children}</>;
}

function Service({ children }: PropsWithChildren) {
  return <>{children}</>;
}

function DetailPage<TEntity extends BaseEntity>(props: IProps<TEntity>) {
  const { uuid, guid } = useParams<{ uuid?: string; guid?: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const apiErrorHandler = useCallback(
    (errors: ApiError[]) => {
      if (props.apiErrors && Array.isArray(errors)) {
        props.apiErrors(
          errors.reduce((acc, err) => {
            acc[err.code] = t(`error:apiError.${err.code}`);
            return acc;
          }, {} as Record<string, string>),
        );
      }
    },
    [props.apiErrors],
  );
  const { main, service, buttons } = useSlots(props.children, {
    service: Service,
    buttons: Buttons,
  });
  const user = AuthService.getUser();

  const { data: entity, refetch } = useQuery(
    [props.queryKey, guid ?? uuid, 'details'],
    () => props.service.get<TEntity>(guid ?? uuid ?? 'false', 'info'),
    {
      enabled: !!(guid ?? uuid),
    },
  );
  useEffect(() => {
    props.entitySetter(entity);
  }, [entity]);

  const handleAction = useCallback((action: UserAction<TEntity>) => {
    setLoading(true);
    if (action.confirm !== undefined) {
      const confirmOptions = action.confirm(entity);
      return confirm({
        title: t(String(confirmOptions.title ?? action.btnText(entity))),
        description: t(String(confirmOptions.description ?? 'common:confirm.title')),
      })
        .then(() => mutation.mutate(action))
        .catch(() => null);
    } else {
      return mutation.mutate(action);
    }
  }, [entity?.uuid]);

  const mutation = useMutation((action: UserAction<TEntity>) => action.action(entity), {
    onSuccess: async (data, action) => {
      enqueueSnackbar(
        action.successMessage ?? t('common:confirm.success', [action.btnText(entity)]),
        { variant: 'success' },
      );
      await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, props.queryKey) });
      if (action.key === 'remove') {
        navigate(props.routes.list());
      } else {
        await refetch();
      }
    },
    onError: (error, action) => {
      const errors = errorHandler(`Failed to ${action.key} ${props.queryKey}`, error);
      if (apiErrorHandler) {
        apiErrorHandler(errors);
      }
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  return (
    <>
      <Title text={t(props.titleKey ?? `${props.queryKey}:details.pageTitle`, { ...props.titleParams?.(entity) })} />
      {!!entity && <Content>
        <Grid2 container rowSpacing={2}>
          <Grid2 container xs={12} lg={9} xl={6}>
            {main}
          </Grid2>
          {user?.isAdmin && service?.length > 0 && (
            <Grid2 xs={12} lg={9} xl={6}>
              <Accordion variant="elevation" disableGutters>
                <AccordionSummary sx={{ my: 0 }}>{t('common:component.DetailPage.serviceInfo')}</AccordionSummary>
                <AccordionDetails>
                  <Grid2 container xs={12} lg={9} xl={6} fontSize="0.7rem" sx={{ '& .MuiTypography-root': { fontSize: '0.7rem' } }}>
                    {service}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            </Grid2>
          )}
          <Grid2 xs={12}>
            <Stack direction="row" spacing={2}>
              {entity?.uuid && props.actions != undefined && props.actions.filter((action) => action.available(entity)).map((action) =>
                action.isNavigate
                  ? <Button key={action.key} variant="outlined" color={action.btnColor?.(entity)} onClick={() => action.action(entity)}>
                    {t('common:button.edit')}
                  </Button>
                  : <LoadingButton key={action.key} loading={props.loading || loading} variant="outlined" color={action.btnColor?.(entity)} onClick={() => handleAction(action)}>
                    {t(action.btnText(entity) ?? 'unknown')}
                  </LoadingButton>
              )}
              {buttons}
              <Button onClick={() => navigate(-1)}>{t('common:button.cancel')}</Button>
            </Stack>
          </Grid2>
        </Grid2>
      </Content>}
    </>
  );
}

DetailPage.Buttons = Buttons;
DetailPage.Service = Service;

export { DetailPage };
