import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { EntityQueryKey } from '../../../query/query.keys';
import {
  ClientBrief,
  LoggedInUser,
  PassportBrief,
  SelectItem,
  SnilsBrief,
  VerificationRequestStatusEnum,
  VerifyingDataEnum,
} from '../../../model';
import { ErrorRoute } from '../../error/routes';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { VerificationService } from '../../../api/verification/verification.service';
import { VerificationRequestBrief } from '../../../model/interfaces/verification';
import { AuthService } from '../../../auth/auth.service';
import { VerificationRequestEditDto, VerifyingDataDto } from '../../../model/dto/verification';
import { VERIFICATION_ROUTES } from '../../route';
import { EditPage } from '../../../components/common/layout/EditPage';
import { EditVerificationRequestValidator } from '../../../service/validator/verification';
import { NotEmpty } from '../../../hooks/UseValidation/validators';
import AutocompleteControl from '../../../components/common/ui/AutocompleteControl';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { ClientService } from '../../../api/client/client.service';
import { BadgedInputControl, FileControl } from '../../../components/common';
import { Dictionary, keyBy } from 'lodash';
import { CheckCircleOutlined } from '@mui/icons-material';
import { Box, Stack, useTheme } from '@mui/material';
import { PassportService } from '../../../api/passport/passport.service';
import SelectControl from '../../../components/common/ui/SelectControl';
import { UserService } from '../../../api/user/user.service';
import { SnilsService } from '../../../api/snils/snils.service';

export function VerificationRequestEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const navigate = useNavigate();
  const theme = useTheme();
  const [, setApiErrors] = useState<Record<string, string>>({});
  const user = AuthService.getUser() as LoggedInUser;

  const { data: entity } = useQuery(
    [EntityQueryKey.Verification, uuid],
    () => VerificationService.get<VerificationRequestBrief>(uuid ?? 'false'),
    {
      enabled: !!uuid,
      onSuccess: (data) => {
        if (data.admin && !user.isAdmin) {
          navigate(ErrorRoute.ACCESS_DENIED, { replace: true });
        }
        setDto({ ...new VerificationRequestEditDto(data) });
        setData(
          keyBy(
            data.data.map(
              (item) =>
                new VerifyingDataDto(
                  item.dataType.guid,
                  item.value,
                  item.dataType.view,
                  item.dataType.code,
                  item.complexValueUuid,
                ),
            ),
            'guid',
          ),
        );
      },
    },
  );
  const [data, setData] = useState<Dictionary<VerifyingDataDto>>(keyBy(
    entity?.data.map(
      (item) =>
        new VerifyingDataDto(
          item.dataType.guid,
          item.value,
          item.dataType.view,
          item.dataType.code,
          item.complexValueUuid,
        ),
    ),
    'guid',
  ));
  const [snilsUuid, setSnilsUuid] = useState<string | null>(null);
  const [passportUuid, setPassportUuid] = useState<string | null>(null);
  const [fioVerified, setFioVerified] = useState(false);
  const [fioOptions, setFioOptions] = useState<SelectItem[]>([]);
  const fioRef = useRef<VerifyingDataDto>();
  useDocumentTitle({ params: [entity?.shortName] });
  const [dto, setDto] = useState(new VerificationRequestEditDto(entity));
  const { data: chosenClient } = useQuery<ClientBrief | undefined>(
    [EntityQueryKey.Client, dto.clientGuid],
    () => dto.clientGuid ? ClientService.get<ClientBrief>(dto.clientGuid) : Promise.resolve(undefined),
  );
  const { data: chosenPassport } = useQuery<PassportBrief | undefined>(
    [EntityQueryKey.Passport, passportUuid],
    () => passportUuid ? PassportService.get<PassportBrief>(passportUuid) : Promise.resolve(undefined),
  );
  const { data: chosenSnils } = useQuery<SnilsBrief | undefined>(
    [EntityQueryKey.Snils, snilsUuid],
    () => snilsUuid ? SnilsService.get<SnilsBrief>(snilsUuid ?? '') : Promise.resolve(undefined),
  );
  const updateDto = useCallback(
    (key: keyof VerificationRequestEditDto, val: any) =>
      setDto((prev) => ({
        ...prev,
        [key]: Array.isArray(val) ? [...val] : val,
      })),
    [],
  );
  useEffect(() => {
    if (!dto.clientGuid) {
      setDto((prev) => ({
        ...prev,
        typeGuid: '',
        data: [],
      }));
      setData({});
    }
  }, [dto.clientGuid]);
  useEffect(() => {
    const options = [];
    const added = [];
    if (chosenClient) {
      added.push(chosenClient.fullName);
      options.push({
        value: chosenClient.fullName,
        text: chosenClient.fullName,
        attrs: { isVerified: chosenClient?.verifiedData.includes(VerifyingDataEnum.FIO) },
      });
    }
    if (chosenPassport) {
      const fullName = UserService.getFullName(chosenPassport as any);
      if (fullName && !added.includes(fullName)) {
        added.push(fullName);
        options.push({
          value: fullName,
          text: fullName,
          attrs: { isVerified: chosenPassport.isVerified },
        });
      }
    }
    if (chosenSnils) {
      const fullName = UserService.getFullName(chosenSnils as any);
      if (fullName && !added.includes(fullName)) {
        added.push(fullName);
        options.push({
          value: fullName,
          text: fullName,
          attrs: { isVerified: chosenSnils.isVerified },
        });
      }
    }
    if (fioRef.current && !added.includes(fioRef.current.value)) {
      fioRef.current.value = '';
    }
    setFioOptions(options);
  }, [chosenClient, chosenPassport, chosenSnils]);

  const getValue = useCallback((client: ClientBrief | undefined, code: string) => {
    switch (code) {
      case VerifyingDataEnum.FIO:
      case VerifyingDataEnum.FULL_NAME:
        return client?.fullName;
      case VerifyingDataEnum.SNILS:
      case VerifyingDataEnum.PASSPORT:
        return '';
      default:
        return client?.[code.toLowerCase()];
    }
  }, []);

  return (
    <EditPage
      titleKey="verification:edit.pageTitle"
      titleParams={[entity?.number]}
      validator={EditVerificationRequestValidator}
      dto={dto}
      queryKey={EntityQueryKey.Verification}
      resetCacheQueryKey={[EntityQueryKey.Client, EntityQueryKey.Snils, EntityQueryKey.Passport]}
      routes={VERIFICATION_ROUTES}
      service={VerificationService}
      apiErrors={setApiErrors}
    >
      <AutocompleteControl
        required
        value={dto.clientGuid}
        entity={EntityQueryKey.Client}
        labelKey="common:field.client"
        options={
          user.isAdmin
            ? AutocompleteService.fetchFn
            : () =>
                Promise.resolve({
                  nextOffset: 0,
                  items: user.clients.map((client) => ({
                    value: client.guid,
                    text: client.view,
                    attrs: { type: client.type },
                  })),
                })
        }
        onChange={(val) => updateDto('clientGuid', val)}
        validators={[NotEmpty]}
        disabled={entity && entity.status !== VerificationRequestStatusEnum.DRAFT}
      />
      <AutocompleteControl
        required
        disabled={!chosenClient || (entity && entity.status !== VerificationRequestStatusEnum.DRAFT)}
        value={dto.typeGuid}
        entity={EntityQueryKey.VerifyType}
        auxQueryKey={chosenClient?.type}
        labelKey="verification:field.type"
        options={(queryKey, term, skip) =>
          AutocompleteService.fetch(queryKey, term, skip, { entityType: chosenClient?.type })
        }
        onChange={(val) => updateDto('typeGuid', val)}
        validators={[NotEmpty]}
      />
      <AutocompleteControl
        required
        multiple
        disabled={!dto.typeGuid || (entity && entity.status !== VerificationRequestStatusEnum.DRAFT)}
        value={Object.keys(data)}
        entity={EntityQueryKey.VerifyingData}
        labelKey="verification:field.data"
        options={(queryKey, term, skip) =>
          AutocompleteService.fetch(queryKey, term, skip, { entityType: chosenClient?.type })
        }
        auxQueryKey={chosenClient?.type}
        onChange={(_, aux) => {
          const newData = aux?.map(
            (val: SelectItem) =>
              data[val.value] ??
              new VerifyingDataDto(
                String(val.value),
                getValue(chosenClient, val.attrs?.code),
                val.text,
                val.attrs?.code,
                null,
              ),
          );
          setData(keyBy(newData, 'guid'));
          updateDto('data', newData);
        }}
        validators={[NotEmpty]}
      />
      {Object.values(data)?.map((item) => {
        switch (item.code) {
          case VerifyingDataEnum.SNILS:
            return (
              <Stack key={item.guid} direction="row" spacing={2} pr={1.75} pt={1} pb={0.5}>
                <AutocompleteControl
                  required
                  value={item.complexValueUuid}
                  entity={EntityQueryKey.Snils}
                  labelKey={item.view}
                  onChange={(val) => {
                    item.complexValueUuid = val as string;
                    setData({ ...data });
                    updateDto('data', Object.values(data));
                    setSnilsUuid(val as string);
                  }}
                  options={(queryKey, term, skip) =>
                    AutocompleteService.fetch(queryKey, term, skip, { client: dto.clientGuid })
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        <span style={{ color: theme.palette[option.attrs?.isActive ? 'success' : 'error'].main }}>{option.text}</span>
                        <CheckCircleOutlined sx={{ ml: 1 }} color={option.attrs?.isVerified ?'success' : 'disabled'}/>
                      </li>
                    );
                  }}
                  validators={[NotEmpty]}
                  disabled={entity && entity.status !== VerificationRequestStatusEnum.DRAFT}
                />
                <Box sx={{ pt: 1 }}>
                  <CheckCircleOutlined color={chosenSnils?.isVerified ? 'success' : 'disabled'} />
                </Box>
              </Stack>
            );
          case VerifyingDataEnum.PASSPORT:
            return (
              <Stack key={item.guid} direction="row" spacing={2} pr={1.75} pt={1} pb={0.5}>
                <AutocompleteControl
                  required
                  value={item.complexValueUuid}
                  entity={EntityQueryKey.Passport}
                  labelKey={item.view}
                  onChange={(val) => {
                    item.complexValueUuid = val as string;
                    setData({ ...data });
                    updateDto('data', Object.values(data));
                    setPassportUuid(val as string);
                  }}
                  options={(queryKey, term, skip) =>
                    AutocompleteService.fetch(queryKey, term, skip, { client: dto.clientGuid })
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        <span style={{ color: theme.palette[option.attrs?.isActive ? 'success' : 'error'].main }}>{option.text}</span>
                        <CheckCircleOutlined sx={{ ml: 1 }} color={option.attrs?.isVerified ?'success' : 'disabled'}/>
                      </li>
                    );
                  }}
                  validators={[NotEmpty]}
                  disabled={entity && entity.status !== VerificationRequestStatusEnum.DRAFT}
                />
                <Box sx={{ pt: 1 }}>
                  <CheckCircleOutlined color={chosenPassport?.isVerified ? 'success' : 'disabled'} />
                </Box>
              </Stack>
            );
          case VerifyingDataEnum.FIO:
            fioRef.current = item;
            return (
              <Stack key={item.guid} direction="row" spacing={2} pr={1.75} pt={1} pb={0.5}>
                <SelectControl
                  required
                  value={item.value}
                  labelKey={item.view}
                  onChange={(val, aux) => {
                    item.value = val as string;
                    setData({ ...data });
                    updateDto('data', Object.values(data));
                    setFioVerified(aux?.attrs?.isVerified === true);
                  }}
                  options={fioOptions}
                  validators={[NotEmpty]}
                  disabled={entity && entity.status !== VerificationRequestStatusEnum.DRAFT}
                />
                <Box sx={{ pt: 1 }}>
                  <CheckCircleOutlined color={fioVerified ? 'success' : 'disabled'} />
                </Box>
              </Stack>
            );
          default:
            return (
              <BadgedInputControl
                key={item.guid}
                disabled
                labelKey={item.view}
                value={item.value}
                onChange={() => false}
                active={chosenClient?.verifiedData.includes(item.code)}
              />
            );
        }
      })}
      <FileControl accept="image/jpeg,image/png,application/pdf" limit={20} labelKey="common:field.files" value={dto.files} onChange={(val) => updateDto('files', val)}/>
    </EditPage>
  );
}
