import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import { IconButton, Link, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CheckCircleOutlined, Edit, SvgIconComponent } from '@mui/icons-material';
import { useMemo } from 'react';

interface IProps {
  labelKey: string;
  text?: string | null;
  to: string;
  state?: any;
  editTo?: string;
  badge?: SvgIconComponent;
  showBadge?: boolean;
  badgeColor?: 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export function LinkDisplay(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Badge = useMemo(() => props.badge ?? CheckCircleOutlined, [props.badge]);
  return <>
    <Grid2 xs={4}>
      <Typography fontWeight="bold" className="display-label">{t(props.labelKey)}</Typography>
    </Grid2>
    <Grid2 xs={8} display="flex" alignItems="center">
      {!!props.to && <>
        <Link component={RouterLink} to={props.to} state={props.state}>{props.text ?? props.to}</Link>
        {props.showBadge !== undefined && <Badge color={props.showBadge ? (props.badgeColor ?? 'success') : 'disabled'} sx={{ ml: 1 }}/>}
      </>}
      {!!props.editTo && <IconButton color="primary" size="small" sx={{ ml: 1 }} aria-label="edit" onClick={() => navigate(props.editTo ?? '')}>
        <Edit sx={{fontSize: '1rem' }} />
      </IconButton>}
    </Grid2>
  </>
}